import ckeditorconfig from "@/libs/ckeditorconfig";
export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "tenant",
    pw: 2000,
    ph: 768,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
  {
    key: "thumbnailId",
    initKey: "thumbnail",
    label: "field.thumbnail",
    type: "async-single-image",
    path: "tenant",
    pw: 1900,
    ph: 1271,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 4,

  },
  {
    key: "logoId",
    initKey: "logo",
    label: "field.logo",
    type: "async-single-image",
    path: "tenant",
    pw: 200,
    ph: 200,
    fullWidth: true,
    disableUploadHistory: true,
    compressMineType: "image/png",
    cols: 4,

  },
  {
    key: "featureImageId",
    initKey: "featureImage",
    label: "field.featureImage",
    type: "async-single-image",
    path: "tenant",
    pw: 1920,
    ph: 1079,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 4,

  },
  {
    key: "businessId",
    label: "field.business",
    rules: "required",
    type: "asyn-single-selection",
    repository: "business",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    type: "text"
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text"
  },

  {
    key: "link",
    label: "field.link",
    type: "text"
  },

  {
    label: "breadcrumb.englishProjectContent",
    icon: "FileTextIcon",
    type: "divider",
  },

  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "contactInformationEn",
    label: "field.contactInformationEn",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    type: "textarea",
    cols: 12
  },


  {
    label: "breadcrumb.khmerProjectContent",
    icon: "FileTextIcon",
    type: "divider",
  },

  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "contactInformationKm",
    label: "field.contactInformationKm",
    type: "ck-editor",
    rules: "required",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },

  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    type: "textarea",
    cols: 12
  },

  {
    label: "breadcrumb.projectGallery",
    icon: "FileTextIcon",
    type: "divider",
    hasDescriptionDimension: true,
    pw: 1920,
    ph: 880,
  },
  {
    key: "projectGalleries",
    hideLabel: true,
    type: "slot",
    cols: 12,
  },

  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },
]
